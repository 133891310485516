<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
                <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

                </v-breadcrumbs>
            </div>
        </v-row><br><br>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">
                <span>Weedoutcode</span>
            </v-card-title>
            <div v-if="init_loading">
                <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
            </div>
            <div v-else>
                <v-card-text>
                    <div class="add-section">
                        <div style="text-align: center;">
                            <v-col>
                                <v-form ref="form">
                                    <label class="add-text">Add Name</label><span style="color: red;"> *</span>
                                    <v-text-field class="text" v-model="weedoutcode" outlined dense
                                        :rules="[rules.required]" autocomplete="off" clearable required></v-text-field>

                                    <label class="add-text">Display Name</label><span style="color: red;"> *</span>
                                    <v-text-field class="text" v-model="display_name" outlined dense
                                        :rules="[rules.required]" autocomplete="off" clearable required></v-text-field>
                                    <v-btn color="success" @click="addWeedoutcode()">Add</v-btn>
                                </v-form>
                            </v-col>
                        </div>
                    </div>

                    <template>
                        <div class="text-center">
                            <v-dialog v-model="dialog" width="500">
                                <v-card>
                                    <v-card-title class="grey lighten-2">
                                        Edit Weedoutcode
                                    </v-card-title>

                                    <v-card-text style="padding-bottom: 0px;">
                                        <label class="add-text">Add Weedoutcode</label><span style="color: red;"> *</span>
                                        <v-text-field class="text" v-model="editName" outlined dense
                                            :rules="[rules.required]" autocomplete="off" clearable required></v-text-field>
                                        <label class="add-text">Display Name</label><span style="color: red;"> *</span>
                                        <v-text-field class="text" v-model="weedoutcodeName" outlined dense
                                            :rules="[rules.required]" clearable required></v-text-field>

                                    </v-card-text>
                                    <div class="text-center">
                                        <v-spacer></v-spacer>
                                        <v-btn class="m-3" @click="dialog = false">
                                            Close
                                        </v-btn>
                                        <v-btn class="m-3" @click="saveEdit()">Update</v-btn>
                                    </div>
                                </v-card>
                            </v-dialog>
                        </div>
                    </template>
                    <div v-if="loader">
                        <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
                    </div>
                    <div v-else>
                        <template v-if="weedoutcode_list != null">
                            <v-data-table :headers="headers" :items="weedoutcode_list" :search="search"
                                class="elevation-1 nowrap">
                                <template v-slot:top>
                                    <v-toolbar flat color="white">
                                        <v-spacer></v-spacer>
                                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table"
                                            single-line hide-details></v-text-field>
                                    </v-toolbar>
                                </template>
                                <template v-slot:item.edit="{ item }">
                                    <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
                                    <v-icon v-if="item" class="mr-2" @click="updateweedoutcode(item)">mdi
                                        mdi-pencil-box</v-icon>
                                </template>
                                <!-- <template v-slot:item.actions="{ item }"> -->
                                <!-- <v-btn :loading="edit_btn_load" @click="editItem(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
                                <!-- <v-switch v-model="item.bookStatusIsactive" @change="editItem(item)"></v-switch>
                        </template> -->
                                <template v-slot:item.delete="{ item }">
                                    <v-icon v-if="item" class="mr-2" @click="deleteWeedoutcode(item)"> mdi
                                        mdi-delete</v-icon>
                                </template>
                            </v-data-table>
                        </template>
                    </div>
                </v-card-text>
            </div>
        </v-card>
        <!-- <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay> -->
    </div>
</template>


<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
    data: () => ({
        init_loading: false,
        loader: false,
        weedoutcode_SA: null,
        snackbar_msg: "",
        snackbar: false,
        color: "",
        items: [
            {
                text: 'Dashboard',
                disabled: false,
                href: '/home',
            },
            {
                text: localStorage.getItem("jhhjbyj"),
                disabled: false,
                href: '/sublink',
            },
            {
                text: 'Admin Master',
                disabled: false,
                href: '/admin-master',
            },
            {
                text: 'Weedoutcode',
                disabled: true,
                href: '',
            },
        ],
        org: null,
        dialog: false,
        search: "",
        weedoutcode: null,
        display_name: null,
        weedoutcodeId: null,
        weedoutcodeName: null,
        userType: null,
        editName: null,
        edit_btn_load: false,
        //weedoutcodelist:[],
        rules: {
            required: (value) => !!value || "Required.",
        },
        weedoutcode_list: null,
        headers: [
            { text: "Sr.No.", value: "srno" },
            { text: "Name", value: "weedoutcode" },
            { text: "Display Name", value: "display_name" },
            { text: "Edit", value: "edit" },
            // { text: "Toggle", value: "actions", sortable: false },
            { text: "Delete", value: "delete" },
        ],
    }),
    watch: {},
    methods: {
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },

        updateweedoutcode(item) {
            this.dialog = true;
            this.weedoutcodeId = item.weedoutcodeId;
            this.editName = item.weedoutcode;
            this.weedoutcodeName = item.display_name;
        },

        saveEdit() {
            // alert("doneeeeeeee");
            if (this.editName) {
                this.editName = this.editName.trim()
            }
            {
                if (this.weedoutcodeName) {
                    this.weedoutcodeName = this.weedoutcodeName.trim()
                }
                console.log("logssssssssss");
                console.log(this.weedoutcodeName);
                const data = {
                    // bookStatusName: this.bookStatusName,
                    weedoutcodeId: this.weedoutcodeId,
                    weedoutcode: this.editName,
                    display_name: this.weedoutcodeName,
                };
                if (this.editName && this.weedoutcodeName) {
                    this.loader = true;
                    axios
                        .post("/admin/editWeedoutcode", data)
                        .then((res) => {
                            if (res.data.msg == "200") {
                                this.loader = false;
                                //this.sec_mode_list = res.data.user_list;
                                this.showSnackbar("#4CAF50", "weedoutcode updated successfully...");
                                this.editName = "",
                                this.weedoutcodeName = "",
                                this.onload();
                                this.dialog = false;
                                
                            } else {
                                this.showSnackbar("#b71c1c", res.data.msg);
                                this.loader = false;
                            }
                        })
                        .catch((error) => {
                            this.loader = false;
                            this.showSnackbar("#b71c1c", "Something went wrong!!!");
                            console.log(error);
                        })
                        .finally(() => {
                        });
                } else {
                    this.showSnackbar("#b71c1c", "Please enter Weedoutcode");
                    this.$refs.form.validate();
                }
            };
        },
        deleteWeedoutcode(item) {
            const data = {
                weedoutcodeId: item.weedoutcodeId,
            };
            axios
                .post("/admin/deleteWeedoutcode", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.showSnackbar("#4CAF50", "Weedoutcode Deleted Successfully  !!");
                        this.onload();
                    }

                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    window.console.log(error);
                });
        },

        onload() {
            this.init_loading = true
            axios
                .post("/admin/getWeedoutcode")
                .then((res) => {
                    this.init_loading = false
                    //this.showSnackbar("#4CAF50","Success")
                    if (res.data.msg == "200") {
                        this.weedoutcode_list = res.data.weedoutcode_list;
                    } else {
                        this.init_loading = false;
                    }
                });
        },
        addWeedoutcode() {

            if (this.weedoutcode == null) {
                this.showSnackbar("#b71c1c", "Please Enter Weedoutcode Name!!!");
                return;
            }
            if (this.display_name == null) {
                this.showSnackbar("#b71c1c", "Please Enter Display Name!!!");
                return;
            }
            const data = {
                weedoutcode: this.weedoutcode,
                display_name: this.display_name,
            };


            this.loader = true;
            axios
                .post("/admin/addWeedoutcode", data)
                .then((res) => {
                    // this.org = res.data.org;
                    // this.weedoutcode_list = res.data.weedoutcode_list;
                    if (res.data.msg == "200") {
                        this.loader = false;
                        this.showSnackbar("#4CAF50", "Weedoutcode added successfully...");
                        //    this.editName ="",
                        // this.weedoutcodeName ="",
                        this.weedoutcode = "",
                            this.display_name = "",
                            this.onload();

                    } else {
                        //this.onload()

                        this.showSnackbar("#b71c1c", res.data.msg);

                    }
                })
                .catch((error) => {
                    this.loader = false;
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.loader = false;
                });


            // this.onload()
        },

    },
    mounted() {
        this.onload();
    },

};

</script>
<style scoped>
.heading {
    background-color: #3f51b5;
    padding: 0.3rem;
    color: white;
}

.v-icon {
    cursor: pointer;
}

.text>>>.v-input__slot {
    background: white !important;
    border-color: hsla(190, 99%, 30%, 1) !important;
}</style>
